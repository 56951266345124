import React, { Fragment } from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { Button } from '@oceane/ui'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import { compose } from 'redux'
import { DateInput, Select } from '@oceane/ui'
import { SELECTION_DEFAULT, SELECTION_PAR_PERIODE, SELECTION_PAR_PROMO } from '../services/resultatsCandidatsActions'
import { injectReferenceATO } from '../../../global/injectDonnneesReference'
import { download } from 'isotope-client/components/download/IsotopeLink'
import { isFormateurPratique, isFormateurTheorique } from '@oceane/ui'

/**
 * Retourne les potentielles erreurs de formulaire
 * @return objet contenant les erreurs (vide si aucune erreur)
 */
const validate = (values, { intl }) => {
	const errors = {}
	const { fieldDateDebut, fieldDateFin, fieldRefAto, fieldCritereSelection } = values

	if (fieldCritereSelection === SELECTION_PAR_PERIODE) {
		// S'il manque une date
		if (!fieldDateDebut || !fieldDateFin) {
			if (!fieldDateDebut) {
				errors.fieldDateDebut = intl.formatMessage({ id: 'global.erreurs.obligatoire' })
			}
			if (!fieldDateFin) {
				errors.fieldDateFin = intl.formatMessage({ id: 'global.erreurs.obligatoire' })
			}
		}
		// Si la date de début est supérieure à la date de fin
		else if (fieldDateDebut > fieldDateFin) {
			errors.fieldDateDebut = intl.formatMessage({ id: 'global.erreurs.date.dateDebutSuperieureDateFin' })
			errors.fieldDateFin = intl.formatMessage({ id: 'global.erreurs.date.dateDebutSuperieureDateFin' })
		}
		// Si la période excède 3 mois
		else {
			const mDebut = new Date(fieldDateDebut).getTime() // Date en millisecondes
			const mFin = new Date(fieldDateFin).getTime() // Date en millisecondes
			const mDiff = mFin - mDebut
			const diff = mDiff / (1000 * 60 * 60 * 24 * 30) // Division par le nb de millisecondes dans un mois

			if (diff > 3) {
				errors.fieldDateDebut = intl.formatMessage({ id: 'global.erreurs.date.periodeTropLongue' }, {
					nombre: 3,
					unite: 'mois'
				})
				errors.fieldDateFin = intl.formatMessage({ id: 'global.erreurs.date.periodeTropLongue' }, {
					nombre: 3,
					unite: 'mois'
				})
			}
		}
	} else if (fieldCritereSelection === SELECTION_PAR_PROMO) {
		if (!fieldRefAto) {
			errors.fieldRefAto = intl.formatMessage({ id: 'global.erreurs.obligatoire' })
		}
	}
	return errors
}

/**
 * Formulaire de la page Résultats Candidats
 */
class ResultatsCandidatsForm extends React.PureComponent {

	constructor(props) {
		super(props)
		this.state = {
			promoRefAtoValue: '',
			critereSelectionValue: SELECTION_DEFAULT
		}
	}

	/**
	 * Contrôle du sélecteur de critère d'export
	 * @param e
	 */
	onCritereSelected = (e) => {
		this.setState({ critereSelectionValue: e.target.value })
	}

	/**
	 * Contrôle du sélecteur de référence ATO
	 * @param e
	 */
	onAtoPicked = (e) => {
		this.setState({ promoRefAtoValue: e.target.value })
	}

	render() {
		const { handleSubmit, intl, referencesATO, formateurPratique, formateurTheorique } = this.props
		const { promoRefAtoValue, critereSelectionValue } = this.state
		return (
			<form>
				<Field
					name="fieldCritereSelection"
					label={intl.formatMessage({ id: 'resultatsCandidats.critere' })}
					value={critereSelectionValue}
					onChange={this.onCritereSelected}
					component={Select}>
					<MenuItem
						value={SELECTION_PAR_PERIODE}>{intl.formatMessage({ id: 'resultatsCandidats.periode.title' })}</MenuItem>
					<MenuItem
						value={SELECTION_PAR_PROMO}>{intl.formatMessage({ id: 'resultatsCandidats.promotion.title' })}</MenuItem>
				</Field>
				{
					critereSelectionValue === SELECTION_PAR_PERIODE &&
					<Fragment>
						<Field
							name="fieldDateDebut"
							type="date"
							label={intl.formatMessage({ id: 'resultatsCandidats.periode.debut' })}
							component={DateInput}
						/>
						<Field
							name="fieldDateFin"
							type="date"
							label={intl.formatMessage({ id: 'resultatsCandidats.periode.fin' })}
							component={DateInput}
						/>
					</Fragment>
				}

				{
					critereSelectionValue === SELECTION_PAR_PROMO &&
					<Fragment>
						<Field
							name="fieldRefAto"
							component={Select}
							label={intl.formatMessage({ id: 'resultatsCandidats.promotion.referenceAto' })}
							displayEmpty
							onChange={this.onAtoPicked}
							value={promoRefAtoValue}>
							<MenuItem
								value="">{intl.formatMessage({ id: 'global.indications.selectEmpty' })}</MenuItem>
							{referencesATO.map((val, key) => (
								<MenuItem
									key={key}
									value={val}>
									{val}
								</MenuItem>
							))}
						</Field>
					</Fragment>
				}

				<Grid container justify="flex-end" spacing={24}>
					{
						formateurTheorique &&
						<Grid item>
							<Button
								variant="contained"
								color="primary"
								onClick={
									handleSubmit((values) => {
											let dlPath = '/files/resultatsCandidats/theorique/'
											values.fieldCritereSelection === SELECTION_PAR_PROMO ?
												dlPath += `promo?promo=${values.fieldRefAto}` :
												dlPath += `periode?debut=${values.fieldDateDebut.replace(/-/g, '/')}&fin=${values.fieldDateFin.replace(/-/g, '/')}`
											// On lance le téléchargement
											download(dlPath)
										}
									)
								}
							>
								<FormattedMessage id="resultatsCandidats.export.theorique"/>
							</Button>
						</Grid>
					}
					{
						formateurPratique &&
						<Grid item>
							<Button
								variant="contained"
								color="primary"
								onClick={
									handleSubmit((values) => {
											let dlPath = '/files/resultatsCandidats/pratique/'
											values.fieldCritereSelection === SELECTION_PAR_PROMO ?
												dlPath += `promo?promo=${values.fieldRefAto}` :
												dlPath += `periode?debut=${values.fieldDateDebut.replace(/-/g, '/')}&fin=${values.fieldDateFin.replace(/-/g, '/')}`
											// On lance le téléchargement
											download(dlPath)
										}
									)
								}
							>
								<FormattedMessage id="resultatsCandidats.export.pratique"/>
							</Button>
						</Grid>
					}
				</Grid>
			</form>
		)
	}
}

const mapStateToProps = (state) => ({
	formateurPratique: isFormateurPratique(state),
	formateurTheorique: isFormateurTheorique(state)
})

const actions = {}

ResultatsCandidatsForm.propTypes = {
	handleSubmit: PropTypes.func,
	referencesATO: PropTypes.array,
	formateurPratique: PropTypes.bool,
	formateurTheorique: PropTypes.bool
}

export default compose(
	injectIntl,
	injectReferenceATO,
	reduxForm({
		form: 'resultatsCandidatsForm',
		validate,
		initialValues: {
			fieldCritereSelection: SELECTION_DEFAULT
		},
		enableReinitialize: true
	}),
	connect(mapStateToProps, actions)
)(ResultatsCandidatsForm)
