import { Button } from '@oceane/ui'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import BackIcon from '@material-ui/icons/ArrowBack'
import { dataTableActions, dataTableValueSelectors } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { approbationFiltresShape } from '../../../config/shapes'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { approuverSelection } from '../services/approbationsActions'
import * as approbationsSelectors from '../services/approbationsSelectors'
import { refreshDonneesReference } from '../../../global/donneesReferenceActions'

const styles = {
	icon: {
		padding: 0,
		margin: '2px 5px 2px 0',
		color: '#000000'
	}
}

class ApprobationsActions extends React.Component {

	render() {
		const {
			classes,
			snackSuccess,
			snackError,
			candidatsSelectionnes,
			approuverSelection,
			filtres,
			clearSelection,
			refreshTable,
			refreshDonneesReference
		} = this.props

		const idCandidats = (candidatsSelectionnes || []).map(candidat => candidat.id)
		return (<Grid container alignItems="center" justify="space-between">
			<Grid item>
				<IconButton
					className={classes.icon}
					onClick={clearSelection}
					disableRipple
				>
					<BackIcon />
				</IconButton>
				<FormattedMessage id="actions.elementsSelectionnes" values={{ selection: idCandidats.length }} />
			</Grid>
			<Grid item>
				<Button
					color="primary"
					onClick={() =>
						approuverSelection(idCandidats, filtres)
							.then(() => {
								snackSuccess({ id: 'approbations.succes', values: { nbFormations: idCandidats.length } })
								clearSelection()
								refreshTable()
								refreshDonneesReference()
							})
							.catch(() => snackError())
					}
				>
					<FormattedMessage id="approbations.approuver" />
				</Button>
			</Grid>
		</Grid>)
	}

}

ApprobationsActions.propTypes = {
	classes: PropTypes.object,
	candidatsSelectionnes: PropTypes.arrayOf(PropTypes.object),
	filtres: approbationFiltresShape,
	approuverSelection: PropTypes.func,
	clearSelection: PropTypes.func,
	refreshTable: PropTypes.func,
	refreshDonneesReference: PropTypes.func
}

const mapStateToProps = (state) => ({
	candidatsSelectionnes: dataTableValueSelectors.getDataTableSelectedRows(state)('approbations'),
	filtres: approbationsSelectors.getFiltres(state)
})

const actions = {
	approuverSelection,
	clearSelection: () => dataTableActions.selectRows('approbations', []),
	refreshTable: () => dataTableActions.refresh('approbations'),
	refreshDonneesReference
}

export default compose(
	connect(mapStateToProps, actions),
	withStyles(styles),
	injectSnackbarActions
)(ApprobationsActions)
