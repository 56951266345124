import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Title } from 'isotope-client'
import { FormattedMessage } from 'react-intl'
import { getNbrDecisionsRestantes } from './services/validationKSAActions'
import { injectActions as injectSnackActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { injectTypesExamenKSA } from '../../global/injectDonnneesReference'
import ValidationKSAActionBar from './components/ValidationKSAActionBar'
import ValidationKSATable from './components/ValidationKSATable'
import * as selectors from './services/validationKSASelectors'

const ValidationKSAPage = ({ nbrDecisionsRestantes, getNbrDecisionsRestantes, snackError }) => {

	React.useEffect(() => {
		getNbrDecisionsRestantes().catch(() => snackError())
	}, [])

	return (
		<>
			<Title value={<FormattedMessage id="approbations.decisionsRestantes" values={{ decisionsRestantes: nbrDecisionsRestantes }} />} />
			<ValidationKSAActionBar />
			<ValidationKSATable />
		</>
	)
}

ValidationKSAPage.propTypes = {
	nbrDecisionsRestantes: PropTypes.number,
	getNbrDecisionsRestantes: PropTypes.func,
	snackError: PropTypes.func
}

const mapStateToProps = (state) => ({
	nbrDecisionsRestantes: selectors.getNbrDecisionsRestantes(state)
})

const actions = {
	getNbrDecisionsRestantes
}

export default compose(
	injectTypesExamenKSA,
	injectSnackActions,
	connect(mapStateToProps, actions)
)(ValidationKSAPage)
