import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem'
import Popover from '@material-ui/core/Popover'
import withStyles from '@material-ui/core/styles/withStyles'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import IconProfile from '@material-ui/icons/PowerSettingsNew'
import { getStyles, logOut } from 'isotope-client'
import { close, open } from 'isotope-client/modules/profile/services/profileActions'
import * as profileSelectors from 'isotope-client/modules/profile/services/profileSelectors'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'

const style = theme => getStyles({
	buttonProfile: {
		width: '40px',
		height: '40px',
		boxShadow: 'none',
		padding: 0,
		color: theme.palette.primary.contrastText
	},
	typoName: {
		marginTop: 8
	},
	icon: {
		fontSize: 29
	},
	menuItem: {
		width: 175
	},
	logoutItem: {
		padding: 8,
		justifyContent: 'center'
	}
})

class ProfileMenu extends Component {
	state = {
		anchorEl: null
	}

	handleClick = event => {
		this.setState({ anchorEl: event.currentTarget })
		this.props.open()
	}

	render() {
		const { classes, opened, close, logOut, username } = this.props

		return (
			<React.Fragment>
				<Tooltip
					disableFocusListener
					title={<FormattedMessage id="tooltip.deconnexion" />}
				>
					<IconButton variant="fab" className={classes.buttonProfile}
					            onClick={(event) => this.handleClick(event)}
					>
						<IconProfile className={classes.icon} />
					</IconButton>
				</Tooltip>
				<Popover
					open={opened}
					onClose={() => {
						close()
					}}
					anchorEl={this.state.anchorEl}
					anchorOrigin={{ vertical: 55, horizontal: 'right' }}
				>
					<ListItem className={classes.menuItem}>
						<Typography className={classes.typoName}>
							{username}
						</Typography>
					</ListItem>
					<Divider />
					<ListItem className={classes.logoutItem}>
						<Button
							onClick={() => {
								close()
								logOut()
							}}
						>
							<Typography variant="body1" color="primary">
								<FormattedMessage id={'global.btn.deconnexion'} />
							</Typography>
						</Button>
					</ListItem>
				</Popover>
			</React.Fragment>
		)
	}
}

ProfileMenu.propTypes = {
	opened: PropTypes.bool.isRequired,
	open: PropTypes.func.isRequired,
	close: PropTypes.func.isRequired,
	logOut: PropTypes.func.isRequired,
	username: PropTypes.string.isRequired
}

const mapStateToProps = (state) => ({
	opened: profileSelectors.openDialog(state),
	username: state.user.username
})

const actions = {
	close,
	open,
	logOut
}

export default compose(
	connect(mapStateToProps, actions),
	withStyles(style)
)(ProfileMenu)
