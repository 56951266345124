import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import { dataTableValueSelectors, getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import ApprobationsActions from './ApprobationsActions'
import ApprobationsFiltres from './ApprobationsFiltres'

const styles = theme => getStyles({
	filtres: {
		backgroundColor: theme.palette.containerFilter,
		padding: '0 20px',
		height: 53,
		alignItems: 'center'
	}
})

class ApprobationsActionBar extends React.Component {

	render() {
		const { classes, candidatsSelectionnes } = this.props
		const hasCandidatsSelectionnes = candidatsSelectionnes && candidatsSelectionnes.length !== 0
		return (
			<Grid alignContent="center" container className={classes.filtres}>
				{hasCandidatsSelectionnes && <ApprobationsActions />}
				{!hasCandidatsSelectionnes && <ApprobationsFiltres />}
			</Grid>
		)
	}
}


ApprobationsActionBar.propTypes = {
	classes: PropTypes.object,
	candidatsSelectionnes: PropTypes.array
}

const mapStateToProps = state => ({
	candidatsSelectionnes: dataTableValueSelectors.getDataTableSelectedRows(state)('approbations')
})

export default compose(
	withStyles(styles),
	connect(mapStateToProps)
)(ApprobationsActionBar)
