import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import { dataTableActions, dataTableValueSelectors, getStyles } from 'isotope-client'
import * as validationKSASelectors from '../services/validationKSASelectors'
import { getTypesExamenKSA } from '../../../global/donneesReferenceActions'
import { VALIDATION_KSA_TABLE_NAME } from './ValidationKSATable'
import { injectActions as injectSnackbarActions } from 'isotope-client/components/snackbar/services/snackbarInjector'
import { Button } from '@oceane/ui'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import BackIcon from '@material-ui/icons/ArrowBack'
import { FormattedMessage } from 'react-intl'
import { getNbrDecisionsRestantes, validerSelection } from '../services/validationKSAActions'
import * as shapes from '../../../config/shapes'

const styles = () => getStyles({
	icon: {
		padding: 0,
		margin: '2px 5px 2px 0',
		color: '#000000'
	}
})

const ValidationKSAActions = (
	{
		classes,
		snackSuccess,
		snackError,
		candidatsSelectionnes,
		validerSelectionKSA,
		filtres,
		clearSelection,
		refreshTable,
		refreshTypesExamen,
		getNbrDecisionsRestantes
	}
) => {
	const idCandidats = (candidatsSelectionnes || []).map(candidat => candidat.idExamenCandidat)
	return (<Grid container alignItems="center" justify="space-between">
		<Grid item>
			<IconButton
				className={classes.icon}
				onClick={clearSelection}
				disableRipple
			>
				<BackIcon />
			</IconButton>
			<FormattedMessage id="actions.elementsSelectionnes" values={{ selection: idCandidats.length }} />
		</Grid>
		<Grid item>
			<Button
				color="primary"
				onClick={() =>
					validerSelectionKSA(idCandidats, filtres)
						.then(() => {
							snackSuccess({ id: 'approbations.succes', values: { nbFormations: idCandidats.length } })
							clearSelection()
							refreshTable()
							refreshTypesExamen()
							getNbrDecisionsRestantes()
						})
						.catch(() => snackError())
				}
			>
				Valider
			</Button>
		</Grid>
	</Grid>)
}

ValidationKSAActions.propTypes = {
	classes: PropTypes.object,
	candidatsSelectionnes: PropTypes.array,
	filtres: shapes.validationKSAShape,
	validerSelctionKSA: PropTypes.func,
	clearSelection: PropTypes.func,
	refreshTable: PropTypes.func,
	refreshTypesExamen: PropTypes.func,
	getNbrDecisionsRestantes: PropTypes.func,
	snackSuccess: PropTypes.func,
	snackError: PropTypes.func
}

const mapStateToProps = (state) => ({
	candidatsSelectionnes: dataTableValueSelectors.getDataTableSelectedRows(state)(VALIDATION_KSA_TABLE_NAME),
	filtres: validationKSASelectors.getFiltres(state)
})

const actions = {
	validerSelectionKSA: validerSelection,
	clearSelection: () => dataTableActions.selectRows(VALIDATION_KSA_TABLE_NAME, []),
	refreshTable: () => dataTableActions.refresh(VALIDATION_KSA_TABLE_NAME),
	refreshTypesExamen: getTypesExamenKSA,
	getNbrDecisionsRestantes
}

export default compose(
	withStyles(styles),
	connect(mapStateToProps, actions),
	injectSnackbarActions
)(ValidationKSAActions)
