import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import { SelectSmallWithPlaceholder, SelectWithPlaceholder } from '@oceane/ui'
import * as referenceSelectors from '../../../global/donneesReferenceSelectors'
import { setFiltreExamen, setFiltreReferenceATO } from '../services/validationKSAActions'
import * as selectors from '../services/validationKSASelectors'
import * as shapes from '../../../config/shapes'
import { injectReferenceATOKSA } from '../../../global/injectDonnneesReference'

const ValidationKSAFiltres = ({ typesExamen, referencesATO, setFiltreExamen, setFiltreReferenceATO, filtres: { typeExamen, referenceATO } }) => (
	<Grid item>
		<SelectWithPlaceholder
			value={typeExamen}
			onChange={event => setFiltreExamen(event.target.value)}
			placeholder="Examen"
			emptyValue={0}
			id="typeExamen"
		>
			{typesExamen.map(type => (
				<MenuItem
					key={type.id}
					value={type.id}
				>
					{type.libelleCourt}
				</MenuItem>
			))}
		</SelectWithPlaceholder>
		{typeExamen > 0 &&
		<SelectSmallWithPlaceholder
			value={referenceATO}
			onChange={event => setFiltreReferenceATO(event.target.value)}
			id="referenceATO"
			placeholder="Référence ATO"
			emptyValue=""
		>
			{referencesATO.map((reference, index) => (
				<MenuItem
					key={index}
					value={reference}
				>
					{reference}
				</MenuItem>
			))}
		</SelectSmallWithPlaceholder>}
	</Grid>
)

ValidationKSAFiltres.propTypes = {
	typesExamen: PropTypes.array,
	filtres: shapes.validationKSAShape,
	setFiltreExamen: PropTypes.func,
	setFiltreReferenceATO: PropTypes.func
}

const mapStateToProps = (state) => ({
	typesExamen: referenceSelectors.getTypesExamenKSA(state),
	filtres: selectors.getFiltres(state),
	referencesATO: referenceSelectors.getReferencesATOKSA(state)
})

const actions = {
	setFiltreExamen,
	setFiltreReferenceATO
}

export default compose(
	injectReferenceATOKSA,
	connect(mapStateToProps, actions)
)(ValidationKSAFiltres)
