import { combineReducers } from 'redux'
import {
	GET_NBR_DECISIONS_RESTANTES_SUCCESS,
	SET_FILTRE_EXAMEN,
	SET_FILTRE_REFERENCE_ATO
} from './validationKSAActions'
import { LOGOUT_SUCCESS } from 'isotope-client'

const nbrDecisionsRestantes = (state = 0, action) => {
	switch (action.type) {
		case GET_NBR_DECISIONS_RESTANTES_SUCCESS:
			return action.decisionsRestantes
		case LOGOUT_SUCCESS:
			return 0
		default:
			return state
	}
}

const filresInit = {
	typeExamen: 0,
	referenceATO: ''
}

const filtres = (state = filresInit, action) => {
	switch (action.type) {
		case SET_FILTRE_EXAMEN:
			return {
				...state,
				typeExamen: action.idTypeExamen,
				referenceATO: ''
			}
		case SET_FILTRE_REFERENCE_ATO:
			return {
				...state,
				referenceATO: action.referenceATO
			}
		case LOGOUT_SUCCESS: {
			return filresInit
		}
		default:
			return state
	}
}

export const validationKSA = combineReducers({
	nbrDecisionsRestantes,
	filtres
})