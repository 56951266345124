import { reducers as reducersIsotope } from 'isotope-client'
import approbationReducers from '../modules/approbationformations/services/approbationsReducers'
import donneesReferenceReducers from '../global/donneesReferenceReducers'
import { reducers } from '@oceane/ui'
import resultatsCandidatsReducers from '../modules/resultatscandidats/services/resultatsCandidatsReducers'
import { validationKSA } from '../modules/validationksa/services/validationKSAReducers'

export default {
	...reducersIsotope,
	...reducers(),
	donneesReference: donneesReferenceReducers,
	approbations: approbationReducers,
	resultatsCandidats: resultatsCandidatsReducers,
	validationKSA
}
