import * as donneesReferenceApi from './donneesReferenceApi'

export const GET_LISTE_REFERENCES_ATO_REQUEST = 'GET_LISTE_REFERENCES_ATO_REQUEST'
export const GET_LISTE_REFERENCES_ATO_SUCCESS = 'GET_LISTE_REFERENCES_ATO_SUCCESS'
export const GET_LISTE_REFERENCES_ATO_ERROR = 'GET_LISTE_REFERENCES_ATO_ERROR'
export const getListeReferencesATO = (formationEnCours) => dispatch => {
	dispatch({ type: GET_LISTE_REFERENCES_ATO_REQUEST })
	return donneesReferenceApi.getListeReferencesATO(formationEnCours)
		.then(result => dispatch({
			type: GET_LISTE_REFERENCES_ATO_SUCCESS,
			formationEnCours,
			referencesATO: result
		}))
		.catch(e => dispatch({
			type: GET_LISTE_REFERENCES_ATO_ERROR,
			e
		}))
}

export const GET_LISTE_REFERENCES_ATO_KSA_REQUEST = 'GET_LISTE_REFERENCES_ATO_KSA_REQUEST'
export const GET_LISTE_REFERENCES_ATO_KSA_SUCCESS = 'GET_LISTE_REFERENCES_ATO_KSA_SUCCESS'
export const GET_LISTE_REFERENCES_ATO_KSA_ERROR = 'GET_LISTE_REFERENCES_ATO_KSA_ERROR'
export const getListeReferencesATOKSA = () => dispatch => {
	dispatch({ type: GET_LISTE_REFERENCES_ATO_KSA_REQUEST })
	return donneesReferenceApi.getListeReferencesATOKSA()
		.then(result => dispatch({
			type: GET_LISTE_REFERENCES_ATO_KSA_SUCCESS,
			referencesATOKSA: result
		}))
		.catch(e => dispatch({
			type: GET_LISTE_REFERENCES_ATO_KSA_ERROR,
			e
		}))
}

export const GET_TYPES_EXAMEN_REQUEST = 'GET_TYPES_EXAMEN_REQUEST'
export const GET_TYPES_EXAMEN_SUCCESS = 'GET_TYPES_EXAMEN_SUCCESS'
export const GET_TYPES_EXAMEN_ERROR = 'GET_TYPES_EXAMEN_ERROR'

/**
 * Liste des types d'examen
 */
export const getTypesExamen = () => dispatch => {
	dispatch({ type: GET_TYPES_EXAMEN_REQUEST })
	return donneesReferenceApi.getListeExamensType()
		.then(result => dispatch({
			type: GET_TYPES_EXAMEN_SUCCESS,
			typesExamen: result
		}))
		.catch(e => dispatch({
			type: GET_TYPES_EXAMEN_ERROR,
			e
		}))
}

export const refreshDonneesReference = () => dispatch => {
	getTypesExamen()(dispatch)
	getListeReferencesATO(false)(dispatch)
	getListeReferencesATO(true)(dispatch)
}

export const GET_TYPES_EXAMEN_KSA_REQUEST = 'GET_TYPES_EXAMEN_KSA_REQUEST'
export const GET_TYPES_EXAMEN_KSA_SUCCESS = 'GET_TYPES_EXAMEN_KSA_SUCCESS'
export const GET_TYPES_EXAMEN_KSA_ERROR = 'GET_TYPES_EXAMEN_KSA_ERROR'
export const getTypesExamenKSA = () => dispatch => {
	dispatch({ type: GET_TYPES_EXAMEN_KSA_REQUEST })
	return donneesReferenceApi.getListeExamensTypeKSA()
		.then(result => dispatch({
			type: GET_TYPES_EXAMEN_KSA_SUCCESS,
			result
		}))
		.catch(e => {
			dispatch({ type: GET_TYPES_EXAMEN_KSA_ERROR, e })
			throw e
		})
}