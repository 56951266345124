import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import { SelectSmallWithPlaceholder, SelectWithPlaceholder } from '@oceane/ui'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { injectReferenceATO, injectTypesExamen } from '../../../global/injectDonnneesReference'
import { setFiltreEpreuve, setFiltreExamen, setFiltreReferenceATO } from '../services/approbationsActions'

import * as approbationSelectors from '../services/approbationsSelectors'


class ApprobationsFiltres extends React.Component {

	render() {
		const { filtres: { typeExamen, typeEpreuve, referenceATO }, typesExamen, typesEpreuve, referencesATOFormationEnCours, setFiltreExamen, setFiltreEpreuve, setFiltreReferenceATO } = this.props
		return (<Grid item>
			<SelectWithPlaceholder
				value={typeExamen}
				onChange={event => setFiltreExamen(event.target.value)}
				placeholder="Examen"
				emptyValue={0}
				id="typeExamen"
			>
				{typesExamen.map(type => (
					<MenuItem
						key={type.id}
						value={type.id}
					>
						{type.libelle}
					</MenuItem>
				))}
			</SelectWithPlaceholder>
			{typeExamen > 0 &&
			<SelectWithPlaceholder
				value={typeEpreuve}
				onChange={event => setFiltreEpreuve(event.target.value)}
				placeholder="Epreuve"
				emptyValue={0}
				id="typeEpreuve"
			>
				{typesEpreuve[typeExamen].map(type => (
					<MenuItem
						key={type.id}
						value={type.id}
					>
						{type.libelle}
					</MenuItem>
				))}
			</SelectWithPlaceholder>}
			{typeEpreuve > 0 &&
			<SelectSmallWithPlaceholder
				value={referenceATO}
				onChange={event => setFiltreReferenceATO(event.target.value)}
				id="referenceATO"
				placeholder="Référence ATO"
				emptyValue=""
			>
				{referencesATOFormationEnCours.map((reference, index) => (
					<MenuItem
						key={index}
						value={reference}
					>
						{reference}
					</MenuItem>
				))}
			</SelectSmallWithPlaceholder>}
		</Grid>)
	}

}

ApprobationsFiltres.propTypes = {
	classes: PropTypes.object,
	typesExamen: PropTypes.array,
	typesEpreuve: PropTypes.object,
	filtres: PropTypes.shape({
		typeExamen: PropTypes.number,
		typeEpreuve: PropTypes.number,
		referenceATO: PropTypes.string
	}),
	referencesATOFormationEnCours: PropTypes.arrayOf(PropTypes.string),
	getListeTypesExamenPourApprobation: PropTypes.func,
	setFiltreExamen: PropTypes.func,
	setFiltreEpreuve: PropTypes.func,
	setFiltreReferenceATO: PropTypes.func
}

const mapStateToProps = (state) => ({
	filtres: approbationSelectors.getFiltres(state)
})

const actions = {
	setFiltreExamen,
	setFiltreEpreuve,
	setFiltreReferenceATO
}

export default compose(
	injectReferenceATO,
	injectTypesExamen,
	connect(mapStateToProps, actions)
)(ApprobationsFiltres)
