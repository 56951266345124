import PropTypes from 'prop-types'

export const approbationFiltresShape = PropTypes.shape({
	typeExamen: PropTypes.number,
	typeEpreuve: PropTypes.number,
	referenceATO: PropTypes.string
})

export const validationKSAShape = PropTypes.shape({
	typeExamen: PropTypes.number,
	referenceATO: PropTypes.string
})