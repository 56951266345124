import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import { replace } from 'connected-react-router'
import { Snackbar, getStyles } from 'isotope-client'


import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import LoginForm from './LoginForm'

const styles = theme => getStyles({
	root: {
		backgroundImage: theme.login.background,
		height: '100%',
		width: '100%',
		backgroundSize: 'cover',
		margin: 0
	},
	page: {
		marginTop: 12,
		marginBottom: 25
	}
})

class OceaneLoginPage extends Component {

	componentDidMount() {
		const { user } = this.props
		if (user.authenticated) {
			replace('/')
		}
	}

	render() {
		document.body.classList.toggle('login-content', true)
		document.body.parentNode.classList.toggle('login-content', true)

		const { location, classes, className: classNameProp } = this.props

		const className = classNames(
			classes.root,
			classNameProp
		)

		return (
			<Grid container alignItems="center" className={className} spacing={16}>
				<Grid item xs={4} lg={7} />
				<Grid item xs={7} lg={4}>
					<Paper>
						<Grid container alignItems="center" justify="center" spacing={16}>
							<Grid item xs={1} />
							<Grid item xs={10} className={classes.page}>
								<LoginForm nextPathname={location.state ? location.state.nextPathname : undefined} />
							</Grid>
							<Grid item xs={1} />
						</Grid>
					</Paper>
				</Grid>
				<Grid item xs={1} />
				<Snackbar/>
			</Grid>
		)
	}
}

OceaneLoginPage.propTypes = {
	location: PropTypes.object,
	classes: PropTypes.object.isRequired,
	className: PropTypes.string
}

const mapStateToProps = (state) => ({
	user: state.user
})

const mapDispatchToProps = (dispatch) => ({
	gotoDashboard: (location) => dispatch(replace({
		pathname: '/login',
		state: {
			nextPathname: location.pathname
		}
	}))
})

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles)
)(OceaneLoginPage)
