import React from 'react'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { FormattedMessage } from 'react-intl'
import { SpringDataTable } from 'isotope-client'
import { EmptyResult } from '@oceane/ui'
import * as selectors from '../services/validationKSASelectors'
import * as shapes from '../../../config/shapes'

export const VALIDATION_KSA_TABLE_NAME = 'validationKSA'

const ValidationKSATable = ({ filtres }) => {
	const headers = [{
		key: 'nom',
		name: <FormattedMessage id="headers.nom" />,
		sortable: false,
		render: (data) => <Typography>{data.nomNaissance}</Typography>
	}, {
		key: 'prenomPrincipal',
		name: <FormattedMessage id="headers.prenoms" />,
		sortable: false,
		render: (data) => <Typography>{data.prenomPrincipal}</Typography>
	}, {
		key: 'dateNaissance',
		name: <FormattedMessage id="headers.dateNaissance" />,
		sortable: false,
		render: (data) => <Typography>{data.dateNaissance}</Typography>
	}, {
		key: 'nationalite',
		name: <FormattedMessage id="headers.nationalite" />,
		sortable: false,
		render: (data) => <Typography>{data.nationalite}</Typography>
	}, {
		key: 'villeNaissance',
		name: <FormattedMessage id="headers.villeNaissance" />,
		sortable: false,
		render: (data) => <Typography>{data.villeNaissance}</Typography>
	}]

	if (filtres && filtres.typeExamen > 0) {
		return <SpringDataTable
			filters={filtres}
			apiUrl="/validation-ksa"
			mode="get"
			headers={headers}
			selectable
			displaySelectAll
			nom={VALIDATION_KSA_TABLE_NAME}
			noResultFragment={<EmptyResult />}
			noPagination
		/>
	}
	return null
}

ValidationKSATable.propTypes = {
	filtres: shapes.validationKSAShape
}

const mapStateToProps = (state) => ({
	filtres: selectors.getFiltres(state)
})

export default connect(mapStateToProps)(ValidationKSATable)
