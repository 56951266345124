import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import { createBrowserHistory } from 'history'
import { createIsotopeTheme, messages as messagesIsotope, configureFetch } from 'isotope-client'
import { fetchUser as fetchUserApi, styles } from '@oceane/ui'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider'
import React from 'react'
import ReactDOM from 'react-dom'
import { addLocaleData } from 'react-intl'
import { intlReducer, Provider } from 'react-intl-redux'
import fr from 'react-intl/locale-data/fr'
import { connectRouter, routerMiddleware, ConnectedRouter } from 'connected-react-router'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { reducer as formReducer } from 'redux-form'
import thunk from 'redux-thunk'
import messages from './config/messages'
import reducers from './config/reducers'
import Root from './containers/Root'
import './index.css'
import { fetchFactory, postMultipart, saveStore } from '@oceane/ui'

const theme = createIsotopeTheme({
	...styles,
	overrides: {
		...styles.overrides,
		MuiTableCell: {
			root: {
				padding: 0,
				'& > button': {
					color: 'black',
				}
			}
		}
	},
	login: {
		background: 'url(\'img/connexion.png\')'
	}
})

// Cette méthode permet d'enfermer tout le code à exécuter après les vérifications sur le login
const reactInit = (user) => {

	// Configuration du custom fetch
	configureFetch({ fetchFactory, postMultipart })

	// Initialisation de la partie i18n (react-intl)
	addLocaleData(fr)
	const formats = {
		date: {
			datetime: {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric'
			}
		}
	}

	// Create a history of your choosing (we're using a browser history in this case)
	const history = createBrowserHistory()

	// Initialisation de tous les enhancers utilisés avec Redux
	const enhancers = [
		applyMiddleware(
			thunk,
			routerMiddleware(history)
		)
	]

	// En mode dév, on utilise le devtools
	if (process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__) {
		enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__())
	}

	// Création du store de redux
	const store = createStore(
		combineReducers({
			...reducers,
			router: connectRouter(history),
			intl: intlReducer,
			form: formReducer
		}), {
			user,
			intl: {
				locale: 'fr',
				messages: {
					...messages,
					...messagesIsotope
				},
				formats: {
					...formats
				},
				defaultLocale: 'fr',
				defaultFormats: {
					...formats
				}
			}
		},
		compose(...enhancers)
	)

	saveStore(store)

	ReactDOM.render(
		<Provider store={store}>
			<ConnectedRouter history={history}>
				<MuiThemeProvider theme={theme}>
					<Root/>
				</MuiThemeProvider>
			</ConnectedRouter>
		</Provider>,
		document.getElementById('root')
	)
}

const token = window.localStorage.getItem('token')
if (token) {
	fetchUserApi(token).then(user => {
		if (user) {
			user.token = token
			user.authenticated = true
		}
		reactInit(user)
	}).catch((error) => {
		console.log(error)
		reactInit()
	})
} else {
	reactInit()
}

// Webpack Hot Module Replacement API
if (module.hot) {
	module.hot.accept('./containers/Root', () => {
		reactInit()
	})
}
