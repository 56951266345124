import { createSelector } from 'reselect'

const getLocalState = state => state.approbations

export const getDecisionsRestantes = createSelector(
	getLocalState,
	approbations => approbations.decisionsRestantes
)

export const getFiltres = createSelector(
	getLocalState,
	approbations => approbations.filtres
)

export const getApprobationError = createSelector(
	getLocalState,
	approbations => approbations.error
)