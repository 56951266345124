import { combineReducers } from 'redux'
import {
	APPROUVER_SELECTION_ERROR,
	APPROUVER_SELECTION_REQUEST,
	APPROUVER_SELECTION_SUCCESS,
	GET_NBR_DECISIONS_RESTANTES_SUCCESS,
	SET_FILTRE_EPREUVE,
	SET_FILTRE_EXAMEN,
	SET_FILTRE_REFERENCE_ATO
} from './approbationsActions'
import { LOCATION_CHANGE } from 'connected-react-router'
import { LOGOUT_SUCCESS } from 'isotope-client'

const decisionsLeftInit = 0
const decisionsRestantes = (state = decisionsLeftInit, action) => {
	switch (action.type) {
		case GET_NBR_DECISIONS_RESTANTES_SUCCESS:
		case APPROUVER_SELECTION_SUCCESS:
			return action.decisionsRestantes
		case LOGOUT_SUCCESS:
			return decisionsLeftInit
		default:
			return state
	}
}

const filresInit = {
	typeExamen: 0,
	typeEpreuve: 0,
	referenceATO: ''
}
const filtres = (state = filresInit, action) => {
	switch (action.type) {
		case SET_FILTRE_EXAMEN:
			return {
				...state,
				typeExamen: action.idTypeExamen,
				typeEpreuve: 0,
				referenceATO: ''
			}
		case SET_FILTRE_EPREUVE:
			return {
				...state,
				typeEpreuve: action.idTypeEpreuve,
				referenceATO: ''
			}
		case SET_FILTRE_REFERENCE_ATO:
			return {
				...state,
				referenceATO: action.referenceATO
			}
		case LOGOUT_SUCCESS: {
			return filresInit
		}
		default:
			return state
	}
}

const error = (state = null, action) => {
	switch (action.type) {
		case APPROUVER_SELECTION_REQUEST:
		case LOCATION_CHANGE:
			return null
		case APPROUVER_SELECTION_ERROR:
			if (action.e.bodyError && action.e.bodyError.id) {
				return action.e.bodyError
			}
			return null
		default:
			return state
	}
}

export default combineReducers({
	decisionsRestantes,
	filtres,
	error
})
