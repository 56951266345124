import * as api from './approbationsApi'

export const GET_NBR_DECISIONS_RESTANTES_REQUEST = 'GET_NBR_DECISIONS_RESTANTES_REQUEST'
export const GET_NBR_DECISIONS_RESTANTES_SUCCESS = 'GET_NBR_DECISIONS_RESTANTES_SUCCESS'
export const GET_NBR_DECISIONS_RESTANTES_ERROR = 'GET_NBR_DECISIONS_RESTANTES_ERROR'

/**
 * Nombre de formations qu'il reste à approuver
 */
export const getNbrDecisionsRestantes = () => dispatch => {
	dispatch({ type: GET_NBR_DECISIONS_RESTANTES_REQUEST })
	return api.getNbDecisionsRestantes()
		.then(value => dispatch({
			type: GET_NBR_DECISIONS_RESTANTES_SUCCESS,
			decisionsRestantes: value
		}))
		.catch(e => dispatch({
			type: GET_NBR_DECISIONS_RESTANTES_ERROR,
			e
		}))
}

export const SET_FILTRE_EXAMEN = 'SET_FILTRE_EXAMEN'
export const setFiltreExamen = idTypeExamen => dispatch => dispatch({
	type: SET_FILTRE_EXAMEN,
	idTypeExamen
})

export const SET_FILTRE_EPREUVE = 'SET_FILTRE_EPREUVE'
export const setFiltreEpreuve = idTypeEpreuve => dispatch => dispatch({
	type: SET_FILTRE_EPREUVE,
	idTypeEpreuve
})


export const SET_FILTRE_REFERENCE_ATO = 'SET_FILTRE_REFERENCE_ATO'
export const setFiltreReferenceATO = referenceATO => dispatch => dispatch({
	type: SET_FILTRE_REFERENCE_ATO,
	referenceATO
})

export const APPROUVER_SELECTION_REQUEST = 'APPROUVER_SELECTION_REQUEST'
export const APPROUVER_SELECTION_SUCCESS = 'APPROUVER_SELECTION_SUCCESS'
export const APPROUVER_SELECTION_ERROR = 'APPROUVER_SELECTION_ERROR'

/**
 * Approuve les examens des candidats sélectionnés
 * @param candidatsSelectionnes liste des candidats
 * @param filtres type d'examen, épreuve, promo
 */
export const approuverSelection = (candidatsSelectionnes, filtres) => dispatch => {
	dispatch({ type: APPROUVER_SELECTION_REQUEST })
	return api.approuverSelection(candidatsSelectionnes, filtres)
		.then(value => dispatch({
			type: APPROUVER_SELECTION_SUCCESS,
			decisionsRestantes: value
		}))
		.catch(e => {
			dispatch({
				type: APPROUVER_SELECTION_ERROR,
				e
			})
			throw e
		})
}
