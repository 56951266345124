import {
	Error404Page,
	Error500Page,
	EtatCivil,
	Layout,
	OceaneContext,
	OceaneLogo,
	handleSession,
	PROFIL_UTILISATEUR,
	refresh as refreshApi,
	RechercheCandidatsPage
} from '@oceane/ui'
import {
	IsotopeContext,
	logOut,
	Page,
	refreshToken as refreshTokenAction,
	storeLocalUser as storeLocalUserApi,
	ToolbarContext
} from 'isotope-client'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import ApprobationPage from '../modules/approbationformations/ApprobationPage'
import ImportCandidatsPage from '../modules/importcandidats/ImportCandidatsPage'
import ResultatsCandidatsPage from '../modules/resultatscandidats/ResultatsCandidatsPage'
import LienManuel from './toolbar/LienManuel'
import NomOrganisme from './toolbar/NomOrganisme'
import ProfileMenu from './toolbar/ProfileMenu'
import Examens from '../modules/examens/Examens'
import ValidationKSAPage from '../modules/validationksa/ValidationKSAPage'

class App extends Component {

	constructor(props) {
		super(props)
		this.refresh()
		this.refreshInterval = setInterval(() => {
			this.refresh()
		}, 1700 * 1000)

	}

	componentDidMount() {
		const { user, setProfil, cleanProfilStored } = this.props
		if (user.authenticated) {
			// Set du profil par défaut à organisme de formation
			setProfil(PROFIL_UTILISATEUR.ORGANISME_FORMATION.id)
		} else {
			// Suppression du profil stocké si le token n'est plus valide
			cleanProfilStored()
		}
	}

	refresh() {
		const { refreshAction, user } = this.props
		if (user && user.token) {
			const token = localStorage.getItem('token')
			refreshApi(token).then((json) => {
				if (json) {
					refreshAction(json.token)
					storeLocalUserApi(json)
				}
			})
		}
	}

	componentWillUnmount() {
		if (this.refreshInterval) {
			clearInterval(this.refreshInterval)
		}
	}

	async checkLogin(location) {
		const { user } = this.props
		if (!user || !user.token) {
			return Promise.resolve(false)
		}
	}

	render() {
		const { location, user } = this.props
		const toolbarElements = [
			<NomOrganisme key="organisme" />,
			<LienManuel key="manuel" />,
			<ProfileMenu key="profil" />
		]
		const titleKey = location.pathname.split('/')[1]

		if (!user.authenticated) {
			return (<Redirect to="/login" />)
		} else {
			return (<IsotopeContext.Provider value={{ appName: 'Oceane', logo: OceaneLogo }}>
					<ToolbarContext.Provider value={{
						title: <FormattedMessage id={`toolbarLabel.${titleKey}`} />,
						children: toolbarElements
					}}>
						<OceaneContext.Provider value={{ application: 'oceane-prt-ato', isPortailCandidat: false }}>
							<Page
								menu={`prt-ato${user.formateurTheorique ? '-formateurThéorique' : ''}${user.importCandidat ? '-importCandidat' : ''}`}
								location={location}>
								<Layout>
									<Switch>
										<Route exact path="/" render={() => (<Redirect
											to={`${user.formateurTheorique ? '/approbation-formations' : '/resultats-candidats'}`} />)} />
										<Route exact path="/approbation-formations" component={ApprobationPage} />
										<Route exact path="/candidats" component={RechercheCandidatsPage} />
										<Route path="/candidats/:id/etat-civil" component={EtatCivil} />
										<Route path="/candidats/:id/examens" component={Examens} />
										<Route exact path="/import-candidats" component={ImportCandidatsPage} />
										<Route exact path="/resultats-candidats" component={ResultatsCandidatsPage} />
										<Route exact path="/validation-100-ksa" component={ValidationKSAPage} />
										<Route path="/erreur*" component={Error500Page} />
										<Route path="*" component={Error404Page} />
									</Switch>
								</Layout>
							</Page>
						</OceaneContext.Provider>
					</ToolbarContext.Provider>
				</IsotopeContext.Provider>
			)
		}
	}

}

App.propTypes = {
	location: PropTypes.object.isRequired,
	logout: PropTypes.func.isRequired,
	refreshAction: PropTypes.func.isRequired,
	user: PropTypes.object
}

const mapStateToProps = (state) => ({
	user: state.user
})

const mapDispatchToProps = (dispatch) => ({
	refreshAction: (token) => dispatch(refreshTokenAction(token)),
	logout: () => dispatch(logOut())
})


export default compose(
	handleSession,
	connect(mapStateToProps, mapDispatchToProps)
)(App)
