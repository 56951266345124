import { createSelector } from 'reselect'

const getLocalState = state => state.donneesReference

export const getReferencesATO = createSelector(
	getLocalState,
	donneesReference => donneesReference.referencesATO
)

export const getReferencesATOFormationEnCours = createSelector(
	getLocalState,
	donneesReference => donneesReference.referencesATOFormationEnCours
)

export const getTypesExamen = createSelector(
	getLocalState,
	donneesReference => donneesReference.typesExamen
)

export const getTypesEpreuveParExamen = createSelector(
	getLocalState,
	donneesReference => donneesReference.typesEpreuveParTypeExamen
)

const getTypesExamenKSAState = createSelector(
	getLocalState,
	state => state.typesExamenKSA
)

const getTypesExamenKSAIds = createSelector(
	getTypesExamenKSAState,
	typesExamenKSA => typesExamenKSA.ids
)

const getTypesExamenKSAById = createSelector(
	getTypesExamenKSAState,
	typesExamenKSA => typesExamenKSA.byId
)

export const getTypesExamenKSA = createSelector(
	getTypesExamenKSAIds,
	getTypesExamenKSAById,
	(ids, byId) =>  ids.map(id => byId[id])
)

export const getReferencesATOKSA = createSelector(
	getLocalState,
	donneesReference => donneesReference.referencesATOKSA
)