import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { getLibelleOrganisme } from '@oceane/ui'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'

const styles = theme => getStyles({
	libelle: {
		color: '#FFFFFF',
		marginRight: 20
	}
})


const NomOrganisme = ({ libelleOrganisme, classes }) => (
	<Typography variant="h6" className={classes.libelle}>
		{libelleOrganisme}
	</Typography>
)

NomOrganisme.propTypes = {
	libelleOrganisme: PropTypes.string
}

const mapStateToProps = state => ({
	libelleOrganisme: getLibelleOrganisme(state)
})

export default compose(
	connect(mapStateToProps),
	withStyles(styles)
)(NomOrganisme)
