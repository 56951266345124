import React from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid'
import { dataTableValueSelectors, getStyles } from 'isotope-client'
import ValidationKSAFiltres from './ValidationKSAFiltres'
import { VALIDATION_KSA_TABLE_NAME } from './ValidationKSATable'
import ValidationKSAActions from './ValidationKSAActions'

const styles = (theme) => getStyles({
	filtres: {
		backgroundColor: theme.palette.containerFilter,
		padding: '0 20px',
		height: 53,
		alignItems: 'center'
	}
})

const ValidationKSAActionBar = ({ candidatsSelectionnes, classes }) => {
	const showActions = candidatsSelectionnes && candidatsSelectionnes.length !== 0
	return (
		<Grid alignContent="center" container className={classes.filtres}>
			{showActions ? <ValidationKSAActions /> : <ValidationKSAFiltres />}
		</Grid>
	)
}

ValidationKSAActionBar.propTypes = {
	candidatsSelectionnes: PropTypes.array
}

const mapStateToProps = (state) => ({
	candidatsSelectionnes: dataTableValueSelectors.getDataTableSelectedRows(state)(VALIDATION_KSA_TABLE_NAME)
})

export default compose(
	withStyles(styles),
	connect(mapStateToProps)
)(ValidationKSAActionBar)
