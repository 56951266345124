import { fetchFactory } from 'isotope-client'

export const getNbDecisionsRestantes = () => fetchFactory('/validation-ksa/nbrDecisionsRestantes')

export const validerSelection = (candidatsSelectionnes, filtres) => fetchFactory('/validation-ksa', {
	method: 'post',
	body: JSON.stringify({
		candidatsSelectionnes,
		validationKSAFiltres: filtres
	})
})