import withStyles from '@material-ui/core/styles/withStyles'
import { Title } from 'isotope-client'
import React, { Fragment } from 'react'
import { injectIntl } from 'react-intl'
import { compose } from 'redux'
import ResultatsCandidatsForm from './components/ResultatsCandidatsForm'
import { checkAuthorities, PROFIL_UTILISATEUR } from '@oceane/ui'

const styles = theme => ({
	title: {
		marginBottom: 15
	}
})

class ResultatsCandidatsPage extends React.PureComponent {

	render() {
		const { classes, intl } = this.props
		return (
			<Fragment>
				<Title className={classes.title} value={intl.formatMessage({ id: 'resultatsCandidats.title' })} />
				<ResultatsCandidatsForm />
			</Fragment>
		)
	}
}

export default compose(
	injectIntl,
	checkAuthorities(PROFIL_UTILISATEUR.ORGANISME_FORMATION),
	withStyles(styles)
)(ResultatsCandidatsPage)
