import * as api from './validationKSAApi'

export const GET_NBR_DECISIONS_RESTANTES_REQUEST = 'VALIDATION_KSA/GET_NBR_DECISIONS_RESTANTES_REQUEST'
export const GET_NBR_DECISIONS_RESTANTES_SUCCESS = 'VALIDATION_KSA/GET_NBR_DECISIONS_RESTANTES_SUCCESS'
export const GET_NBR_DECISIONS_RESTANTES_ERROR = 'VALIDATION_KSA/GET_NBR_DECISIONS_RESTANTES_ERROR'

/**
 * Nombre de formations qu'il reste à approuver
 */
export const getNbrDecisionsRestantes = () => dispatch => {
	dispatch({ type: GET_NBR_DECISIONS_RESTANTES_REQUEST })
	return api.getNbDecisionsRestantes()
		.then(value => dispatch({
			type: GET_NBR_DECISIONS_RESTANTES_SUCCESS,
			decisionsRestantes: value
		}))
		.catch(e => {
			dispatch({
				type: GET_NBR_DECISIONS_RESTANTES_ERROR,
				e
			})
			throw e
		})
}

export const SET_FILTRE_EXAMEN = 'VALIDATION_KSA/SET_FILTRE_EXAMEN'
export const setFiltreExamen = idTypeExamen => dispatch => dispatch({
	type: SET_FILTRE_EXAMEN,
	idTypeExamen
})

export const SET_FILTRE_REFERENCE_ATO = 'VALIDATION_KSA/SET_FILTRE_REFERENCE_ATO'
export const setFiltreReferenceATO = referenceATO => dispatch => dispatch({
	type: SET_FILTRE_REFERENCE_ATO,
	referenceATO
})

export const VALIDER_SELECTION_REQUEST = 'VALIDATION_KSA/VALIDER_SELECTION_REQUEST'
export const VALIDER_SELECTION_SUCCESS = 'VALIDATION_KSA/VALIDER_SELECTION_SUCCESS'
export const VALIDER_SELECTION_ERROR = 'VALIDATION_KSA/VALIDER_SELECTION_ERROR'

/**
 * Effectue la validation 100 KSA des examens des candidats sélectionnés
 * @param candidatsSelectionnes liste des candidats
 * @param filtres type d'examen, épreuve, promo
 */
export const validerSelection = (candidatsSelectionnes, filtres) => dispatch => {
	dispatch({ type: VALIDER_SELECTION_REQUEST })
	return api.validerSelection(candidatsSelectionnes, filtres)
		.then(() => dispatch({ type: VALIDER_SELECTION_SUCCESS }))
		.catch(e => {
			dispatch({ type: VALIDER_SELECTION_ERROR, e })
			throw e
		})
}
