import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as donneesReferenceSelectors from './donneesReferenceSelectors'
import {
	getListeReferencesATO,
	getListeReferencesATOKSA,
	getTypesExamen,
	getTypesExamenKSA
} from './donneesReferenceActions'

export const injectReferenceATO = (WrappedComponent) => {

	class getDonneesReferenceInjector extends React.Component {

		componentDidMount() {
			const { referencesATO, getListeReferencesATO, referencesATOFormationEnCours } = this.props
			if (!referencesATO || referencesATO.length === 0) {
				getListeReferencesATO(false)
			}
			if (!referencesATOFormationEnCours || referencesATOFormationEnCours.length === 0) {
				getListeReferencesATO(true)
			}
		}

		render() {
			return (<WrappedComponent {...this.props} />)
		}

	}

	getDonneesReferenceInjector.propTypes = {
		referencesATO: PropTypes.arrayOf(PropTypes.string),
		referencesATOFormationEnCours: PropTypes.arrayOf(PropTypes.string),
		getListeReferencesATO: PropTypes.func
	}

	const mapStateToProps = state => ({
		referencesATO: donneesReferenceSelectors.getReferencesATO(state),
		referencesATOFormationEnCours: donneesReferenceSelectors.getReferencesATOFormationEnCours(state)
	})

	const actions = {
		getListeReferencesATO
	}

	return connect(mapStateToProps, actions)(getDonneesReferenceInjector)

}
export const injectReferenceATOKSA = (WrappedComponent) => {

	class getDonneesReferenceInjector extends React.Component {

		componentDidMount() {
			const { referencesATO, getListeReferencesATO } = this.props
			if (!referencesATO || referencesATO.length === 0) {
				getListeReferencesATO(false)
			}
		}

		render() {
			return (<WrappedComponent {...this.props} />)
		}

	}

	getDonneesReferenceInjector.propTypes = {
		referencesATO: PropTypes.arrayOf(PropTypes.string),
		getListeReferencesATO: PropTypes.func
	}

	const mapStateToProps = state => ({
		referencesATO: donneesReferenceSelectors.getReferencesATO(state),
	})

	const actions = {
		getListeReferencesATO: getListeReferencesATOKSA
	}

	return connect(mapStateToProps, actions)(getDonneesReferenceInjector)

}

export const injectTypesExamen = (WrappedComponent) => {

	class getTypesExamenInjector extends React.Component {

		componentDidMount() {
			const { typesExamen, getTypesExamen } = this.props
			if (!typesExamen || typesExamen.length === 0) {
				getTypesExamen()
			}
		}

		render() {
			const { getTypesExamen, ...otherProps } = this.props
			return (<WrappedComponent {...otherProps} />)
		}

	}

	getTypesExamenInjector.propTypes = {
		typesEpreuve: PropTypes.object.isRequired,
		typesExamen: PropTypes.array.isRequired,
		getTypesExamen: PropTypes.func
	}

	const mapStateToProps = state => ({
		typesExamen: donneesReferenceSelectors.getTypesExamen(state),
		typesEpreuve: donneesReferenceSelectors.getTypesEpreuveParExamen(state)
	})

	const actions = {
		getTypesExamen
	}

	return connect(mapStateToProps, actions)(getTypesExamenInjector)

}

export const injectTypesExamenKSA = WrappedComponent => {
	const TypesExamenKSAInjector = ({ getTypesExamenKSA, ...props }) => {
		React.useEffect(() => {
			getTypesExamenKSA()
		}, [])

		return <WrappedComponent {...props} />
	}

	TypesExamenKSAInjector.propTypes = {
		getTypesExamenKSA: PropTypes.func
	}

	const actions = {
		getTypesExamenKSA
	}

	return connect(undefined, actions)(TypesExamenKSAInjector)
}