import { fetchFactory } from 'isotope-client'

export const getNbDecisionsRestantes = () => fetchFactory('/approbations/nbrDecisionsRestantes')

export const approuverSelection = (candidatsSelectionnes, filtres) => fetchFactory('/approbations/approuver', {
	method: 'post',
	body: JSON.stringify({
		candidatsSelectionnes,
		approbationsFiltres: filtres
	})
})