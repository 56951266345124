import { combineReducers } from 'redux'
import { LOGOUT_SUCCESS } from 'isotope-client'

import {
	GET_LISTE_REFERENCES_ATO_KSA_SUCCESS,
	GET_LISTE_REFERENCES_ATO_SUCCESS,
	GET_TYPES_EXAMEN_KSA_SUCCESS,
	GET_TYPES_EXAMEN_SUCCESS
} from './donneesReferenceActions'

const referencesATOInit = []
const referencesATO = (state = referencesATOInit, action) => {
	switch (action.type) {
		case GET_LISTE_REFERENCES_ATO_SUCCESS:
			if (!action.formationEnCours) {
				return action.referencesATO
			}
			return state
		case LOGOUT_SUCCESS:
			return referencesATOInit
		default:
			return state
	}
}

const referencesATOFormationEnCoursInit = []
const referencesATOFormationEnCours = (state = referencesATOFormationEnCoursInit, action) => {
	switch (action.type) {
		case GET_LISTE_REFERENCES_ATO_SUCCESS:
			if (action.formationEnCours) {
				return action.referencesATO
			}
			return state
		case LOGOUT_SUCCESS:
			return referencesATOFormationEnCoursInit
		default:
			return state
	}
}


const typesExamenInit = []
const typesExamen = (state = typesExamenInit, action) => {
	switch (action.type) {
		case GET_TYPES_EXAMEN_SUCCESS:
			return action.typesExamen
		case LOGOUT_SUCCESS:
			return typesExamenInit
		default:
			return state
	}
}

const typesEpreuveParIdTypeExamenInit = {}
const typesEpreuveParTypeExamen = (state = typesEpreuveParIdTypeExamenInit, action) => {
	switch (action.type) {
		case GET_TYPES_EXAMEN_SUCCESS:
			return action.typesExamen.reduce((acc, typeExamen) => {
				acc[typeExamen.id] = typeExamen.epreuvesType
				return acc
			}, {})
		case LOGOUT_SUCCESS:
			return typesEpreuveParIdTypeExamenInit
		default:
			return state
	}
}

const typesExamenKSAIds = (state = [], action) => {
	switch(action.type) {
		case GET_TYPES_EXAMEN_KSA_SUCCESS:
			return action.result.map(examen => examen.id)
		case LOGOUT_SUCCESS:
			return []
		default:
			return state
	}
}

const typesExamenKSAById = (state = {}, action) => {
	switch(action.type) {
		case GET_TYPES_EXAMEN_KSA_SUCCESS:
			return action.result.reduce((acc, examen) => ({
				...acc,
				[examen.id]: examen
			}), state)
		default:
			return state
	}
}

const referencesATOKSAInit = []
const referencesATOKSA = (state = referencesATOKSAInit, action) => {
	switch (action.type) {
		case GET_LISTE_REFERENCES_ATO_KSA_SUCCESS:
			if (!action.formationEnCours) {
				return action.referencesATOKSA
			}
			return state
		case LOGOUT_SUCCESS:
			return referencesATOKSAInit
		default:
			return state
	}
}

export default combineReducers({
	referencesATO,
	referencesATOFormationEnCours,
	typesExamen,
	typesEpreuveParTypeExamen,
	typesExamenKSA: combineReducers({
		ids: typesExamenKSAIds,
		byId: typesExamenKSAById
	}),
	referencesATOKSA
})

