import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import Typography from '@material-ui/core/Typography'
import { FormattedMessage } from 'react-intl'
import { SpringDataTable } from 'isotope-client'
import { approbationFiltresShape } from '../../../config/shapes'
import { EmptyResult } from '@oceane/ui'
import * as approbationSelectors from '../services/approbationsSelectors'


class ApprobationsTable extends React.Component {

	render() {
		const { filtres } = this.props

		const headers = [{
			key: 'nom',
			name: <FormattedMessage id="headers.nom" />,
			sortable: false,
			render: (data, row) => <Typography>{data.nomNaissance}</Typography>
		}, {
			key: 'prenomPrincipal',
			name: <FormattedMessage id="headers.prenoms" />,
			sortable: false,
			render: (data, row) => <Typography>{data.prenomPrincipal}</Typography>
		}, {
			key: 'dateNaissance',
			name: <FormattedMessage id="headers.dateNaissance" />,
			sortable: false,
			render: (data, row) => <Typography>{data.dateNaissance}</Typography>
		}, {
			key: 'nationalite',
			name: <FormattedMessage id="headers.nationalite" />,
			sortable: false,
			render: (data, row) => <Typography>{data.nationalite}</Typography>
		}, {
			key: 'villeNaissance',
			name: <FormattedMessage id="headers.villeNaissance" />,
			sortable: false,
			render: (data, row) => <Typography>{data.villeNaissance}</Typography>
		}]

		if (filtres && filtres.typeEpreuve > 0) {
			return <SpringDataTable
				filters={filtres}
				apiUrl="/approbations"
				mode="post"
				headers={headers}
				selectable
				displaySelectAll
				nom="approbations"
				defaultPageSize={1000}
				pageSizes={[1000]}
				noResultFragment={<EmptyResult />}
			/>
		}
		return null
	}

}

ApprobationsTable.propTypes = {
	filtres: approbationFiltresShape,
	classes: PropTypes.object,
	updateSelection: PropTypes.func
}

const mapStateToProps = (state) => ({
	filtres: approbationSelectors.getFiltres(state)
})


export default compose(
	connect(mapStateToProps)
)(ApprobationsTable)
