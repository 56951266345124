import IconButton from '@material-ui/core/IconButton'
import withStyles from '@material-ui/core/styles/withStyles'
import HelpIcon from '@material-ui/icons/Help'
import { LinkToStaticFile, checkAuthorities, PROFIL_UTILISATEUR, Error } from '@oceane/ui'
import { Title } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { compose } from 'redux'
import ApprobationsActionBar from './components/ApprobationsActionBar'
import ApprobationsTable from './components/ApprobationsTable'
import { getNbrDecisionsRestantes } from './services/approbationsActions'
import * as approbationSelectors from './services/approbationsSelectors'

const styles = theme => ({
	icon: {
		color: theme.palette.primary.main
	}
})

class ApprobationPage extends React.Component {

	componentDidMount() {
		const { getNbrDecisionsRestantes } = this.props
		getNbrDecisionsRestantes()
	}

	render() {
		const { decisionsLeft, error, classes } = this.props

		return (
			<React.Fragment>
				<Error error={error}/>
				<Title value={<React.Fragment>
					<FormattedMessage id="approbations.decisionsRestantes"
					                  values={{ decisionsRestantes: decisionsLeft }} />
					<LinkToStaticFile url={'/html/PRT_consignesFTO_approbation.html'}>
						<IconButton className={classes.icon} disableRipple>
							<HelpIcon />
						</IconButton>
					</LinkToStaticFile>
				</React.Fragment>}
				/>
				<ApprobationsActionBar />
				<ApprobationsTable />
			</React.Fragment>
		)
	}
}


ApprobationPage.propTypes = {
	classes: PropTypes.object,
	decisionsLeft: PropTypes.number,
	getNbrDecisionsRestantes: PropTypes.func,
	getListeTypesExamenPourApprobation: PropTypes.func
}

const mapStateToProps = state => ({
	decisionsLeft: approbationSelectors.getDecisionsRestantes(state),
	error: approbationSelectors.getApprobationError(state)
})

const actions = {
	getNbrDecisionsRestantes
}

export default compose(
	withStyles(styles),
	checkAuthorities(PROFIL_UTILISATEUR.ORGANISME_FORMATION),
	connect(mapStateToProps, actions)
)(ApprobationPage)
