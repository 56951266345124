import * as api from './resultatsCandidatsApi'

export const GET_RESULTATS_PERIODE_REQUEST = 'GET_RESULTATS_PERIODE_REQUEST'
export const GET_RESULTATS_PERIODE_SUCCESS = 'GET_RESULTATS_PERIODE_SUCCESS'
export const GET_RESULTATS_PERIODE_ERROR = 'GET_RESULTATS_PERIODE_ERROR'

export const GET_RESULTATS_PROMO_REQUEST = 'GET_RESULTATS_PROMO_REQUEST'
export const GET_RESULTATS_PROMO_SUCCESS = 'GET_RESULTATS_PROMO_SUCCESS'
export const GET_RESULTATS_PROMO_ERROR = 'GET_RESULTATS_PROMO_ERROR'

export const SELECTION_PAR_PERIODE = 'periode'
export const SELECTION_PAR_PROMO = 'promo'
export const SELECTION_DEFAULT = SELECTION_PAR_PERIODE
export const TYPE_EXPORT_THEORIQUE = 'theorique'
export const TYPE_EXPORT_PRATIQUE = 'pratique'

/**
 * Export CSV des examens théoriques ou pratiques dans une période donnée
 * @param typeExamen théorique ou pratique
 * @param dateDebut date de début
 * @param dateFin date de fin
 * @returns {function(*): Q.Promise<any>}
 */
export const getResultatsCandidatsPeriode = (typeExamen, dateDebut, dateFin) => dispatch => {
	dispatch({ type: GET_RESULTATS_PERIODE_REQUEST })

	dateDebut = dateDebut.replace(/-/g,'/')
	dateFin = dateFin.replace(/-/g,'/')

	if (typeExamen === TYPE_EXPORT_THEORIQUE) {
		return api.getResultatsCandidatsPeriodeTheorique(dateDebut, dateFin)
			.then(value => dispatch({
				type: GET_RESULTATS_PERIODE_SUCCESS,
				payload: value
			}))
			.catch(e => dispatch({
				type: GET_RESULTATS_PERIODE_ERROR,
				e
			}))
	} else if (typeExamen === TYPE_EXPORT_PRATIQUE) {
		return api.getResultatsCandidatsPeriodePratique(dateDebut, dateFin)
			.then(value => dispatch({
				type: GET_RESULTATS_PERIODE_SUCCESS,
				payload: value
			}))
			.catch(e => dispatch({
				type: GET_RESULTATS_PERIODE_ERROR,
				e
			}))
	}
}

/**
 * Export CSV des examens théoriques ou pratiques dans une promoRefAtoValue donnée
 * @param typeExamen théorique ou pratique
 * @param promo promotion concernée
 * @returns {function(*): Q.Promise<any>}
 */
export const getResultatsCandidatsPromo = (typeExamen, promo) => dispatch => {
	if (typeExamen === TYPE_EXPORT_THEORIQUE) {
		dispatch({ type: GET_RESULTATS_PROMO_REQUEST })
		return api.getResultatsCandidatsPromoTheorique(promo)
			.then(value => dispatch({
				type: GET_RESULTATS_PROMO_SUCCESS,
				payload: value
			}))
			.catch(e => dispatch({
				type: GET_RESULTATS_PROMO_ERROR,
				e
			}))
	}
	else if(typeExamen === TYPE_EXPORT_PRATIQUE) {
		dispatch({ type: GET_RESULTATS_PROMO_REQUEST })
		return api.getResultatsCandidatsPromoPratique(promo)
			.then(value => dispatch({
				type: GET_RESULTATS_PROMO_SUCCESS,
				payload: value
			}))
			.catch(e => dispatch({
				type: GET_RESULTATS_PROMO_ERROR,
				e
			}))
	}

}

export const onSubmitAction = (values) => dispatch => {
	if(values.fieldCritereSelection === SELECTION_PAR_PROMO) {
		dispatch(getResultatsCandidatsPromo(values.typeExport, values.fieldRefAto))
	}
	else if(values.fieldCritereSelection === SELECTION_PAR_PERIODE) {
		dispatch(getResultatsCandidatsPeriode(values.typeExport, values.fieldDateDebut, values.fieldDateFin))
	}
}
