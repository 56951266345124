import { createSelector } from 'reselect'

const getLocalState = state => state.validationKSA

export const getNbrDecisionsRestantes = createSelector(
	getLocalState,
	state => state.nbrDecisionsRestantes
)

export const getFiltres = createSelector(
	getLocalState,
	approbations => approbations.filtres
)
