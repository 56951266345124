import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import withStyles from '@material-ui/core/styles/withStyles'
import HelpIcon from '@material-ui/icons/Help'
import { infoShape, injectInfos } from '@oceane/ui'
import { getStyles } from 'isotope-client'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { compose } from 'redux'

const style = theme => getStyles({
	icon: {
		color: '#FFFFFF'
	}
})


class LienManuel extends React.Component {

	render() {
		const { classes, infos: { urlManuelsAto } } = this.props
		return (
			<Tooltip
				disableFocusListener
				title={<FormattedMessage id="tooltip.manuel" />}
			>
				<a href={urlManuelsAto} target="_blank" rel="noopener noreferrer">
					<IconButton
						className={classes.icon}
					>
						<HelpIcon />
					</IconButton>
				</a>
			</Tooltip>
		)
	}

}

LienManuel.propTypes = {
	classes: PropTypes.object,
	infos: infoShape
}

export default compose(
	withStyles(style),
	injectInfos
)(LienManuel)
